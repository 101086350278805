import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";

import reducers from "../reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [];

const { REACT_APP_STAGE } = process.env;

if (REACT_APP_STAGE === "LOCALHOST" || REACT_APP_STAGE === "DEVELOPMENT") {
  middlewares.push(logger);
}

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk, ...middlewares))
);

export default store;
