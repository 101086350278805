import API from "../../api";

export const GET_HOME = "GET_HOME";
export const GET_HOME_ERROR = "GET_HOME_ERROR";

export async function getHome(dispatch) {
  const response = await API.get("home");

  if (response.status === 200) {
    return dispatch({
      type: GET_HOME,
      payload: response.data || [],
    });
  }

  return dispatch({ type: GET_HOME_ERROR, payload: response });
}
