import { GET_TITLES, FETCH_ERROR } from "../actions/titles";

const INITIAL_STATE = {
  titles: {},
  fetch_error: false,
};

export default function (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case GET_TITLES:
      return {
        ...state,
        titles: payload,
        fetch_error: false,
      };
    case FETCH_ERROR:
      return {
        fetch_error: payload,
      };
    default:
      return state;
  }
}
