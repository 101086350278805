import axios from "axios";

const { REACT_APP_STAGE } = process.env;

const API = axios.create({
  baseURL: process.env[`REACT_APP_API_URL_${REACT_APP_STAGE}`],
  headers: {
    "Access-Control-Allow-Origin":
      process.env[`REACT_APP_ORIGIN_${REACT_APP_STAGE}`],
  },
});

export default API;
