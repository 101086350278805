import API from "../../api";

export const GET_GENRES = "GET_GENRES";
export const GET_GENRES_ERROR = " GET_GENRES_ERROR";

export async function getGenres(dispatch) {
  const response = await API.get("generos");

  if (response.status === 200) {
    return dispatch({
      type: GET_GENRES,
      payload: response.data || [],
    });
  }

  return dispatch({ type: GET_GENRES_ERROR, payload: response });
}
