import { combineReducers } from "redux";

import titlesReducer from "./titles";
import genresReducer from "./genres";
import genreReducer from "./genre";
import homeReducer from "./home";
import featuredReducer from "./featured";
import channelsReducer from "./channels";
import rentReducer from "./rent";
import menuReducer from './menu'

export default combineReducers({
  titlesReducer,
  genresReducer,
  genreReducer,
  homeReducer,
  featuredReducer,
  channelsReducer,
  rentReducer,
  menuReducer
});
