import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import "bootstrap/dist/css/bootstrap.min.css";
import "rc-drawer/assets/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/scss/style.scss";

serviceWorkerRegistration.unregister();

function ReduxApp() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
}

ReactDOM.render(<ReduxApp />, document.getElementById("root"));
