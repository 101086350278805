import API from "../../api";

export const GET_CHANNELS = "GET_CHANNELS";
export const GET_CHANNELS_ERROR = " GET_CHANNELS_ERROR";

export async function getChannels(dispatch) {
  const response = await API.get("channels");

  if (response.status === 200) {
    return dispatch({
      type: GET_CHANNELS,
      payload: response.data || [],
    });
  }
  return dispatch({ type: GET_CHANNELS_ERROR, payload: response });
}
