import { GET_CHANNELS, GET_CHANNELS_ERROR } from "../actions/channels";

const INITIAL_STATE = {
  channels: [],
  fetch_error: "",
};

export default function (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case GET_CHANNELS:
      return {
        ...state,
        channels: payload,
        fetch_error: "",
      };
    case GET_CHANNELS_ERROR:
      return {
        fetch_error: payload,
      };
    default:
      return state;
  }
}
