import { GET_HOME, GET_HOME_ERROR } from "../actions/home";

const INITIAL_STATE = {
  home: [],
  fetch_error: "",
};

export default function (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case GET_HOME:
      return {
        ...state,
        home: payload,
        fetch_error: "",
      };
    case GET_HOME_ERROR:
      return {
        fetch_error: payload,
      };
    default:
      return state;
  }
}
