import API from "../../api";

export const GET_FEATURED = "GET_FEATURED";
export const GET_FEATURED_ERROR = " GET_FEATURED_ERROR";

export async function getFeatured(dispatch) {
  const response = await API.get("banners");

  if (response.status === 200) {
    return dispatch({
      type: GET_FEATURED,
      payload: response.data || [],
    });
  }

  return dispatch({ type: GET_FEATURED_ERROR, payload: response });
}
