import { GET_GENRE, GET_GENRE_ERROR } from "../actions/genre";

const INITIAL_STATE = {
  genre: false,
  fetchError: "",
};

export default function (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case GET_GENRE:
      return {
        ...state,
        genre: payload,
        fetchError: "",
      };
    case GET_GENRE_ERROR:
      return {
        ...state,
        fetchError: payload,
      };
    default:
      return state;
  }
}
