import { GET_MENU, GET_MENU_ERROR } from "../actions/menu";

const INITIAL_STATE = {
  menu: [],
  fetch_error: "",
};

export default function (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case GET_MENU:
      return {
        ...state,
        menu: payload,
        fetch_error: "",
      };
    case GET_MENU_ERROR:
      return {
        fetch_error: payload,
      };
    default:
      return state;
  }
}
