import { GET_FEATURED, GET_FEATURED_ERROR } from "../actions/featured";

const INITIAL_STATE = {
  featured: [],
  fetch_error: "",
};

export default function (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case GET_FEATURED:
      return {
        ...state,
        featured: payload,
        fetch_error: "",
      };
    case GET_FEATURED_ERROR:
      return {
        fetch_error: payload,
      };
    default:
      return state;
  }
}
