import API from "../../api";

export const GET_MENU = "GET_MENU";
export const GET_MENU_ERROR = " GET_MENU_ERROR";

export async function getMenu(dispatch) {
  const response = await API.get("v1/menu/menu-web-logged-out-config");

  if (response.status === 200) {
    return dispatch({
      type: GET_MENU,
      payload: response.data || [],
    });
  }

  return dispatch({ type: GET_MENU_ERROR, payload: response });
}
