import API from "../../api";

export const GET_RENT = "GET_RENT";
export const FETCH_ERROR = "FETCH_ERROR";

export async function getRent(dispatch) {
  const response = await API.get("alugue");

  if (response.status === 200) {
    return dispatch({
      type: GET_RENT,
      payload: response.data || [],
    });
  }

  return dispatch({
    type: FETCH_ERROR,
    payload: {
      type: "error",
      rent: response,
    },
  });
}
