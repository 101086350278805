import API from "../../api";

export const GET_GENRE = "GET_GENRE";
export const GET_GENRE_ERROR = " GET_GENRE_ERROR";

export async function getGenre(dispatch, id) {
  const response = await API.get(`generos/${id}`);

  if (response.status === 200) {
    return dispatch({
      type: GET_GENRE,
      payload: response.data || [],
    });
  }

  return dispatch({ type: GET_GENRE_ERROR, payload: response });
}
