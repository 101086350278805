import { GET_GENRES, GET_GENRES_ERROR } from "../actions/genres";

const INITIAL_STATE = {
  genres: [],
  fetchError: "",
};

export default function (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case GET_GENRES:
      return {
        ...state,
        genres: payload,
        fetchError: "",
      };
    case GET_GENRES_ERROR:
      return {
        ...state,
        fetchError: payload,
      };
    default:
      return state;
  }
}
