import API from "../../api";

export const GET_TITLES = "GET_TITLES";
export const FETCH_ERROR = "FETCH_ERROR";

export async function getTitles(dispatch) {
  const response = await API.get("titulos");

  if (response.status === 200) {
    return dispatch({
      type: GET_TITLES,
      payload: response.data || [],
    });
  }

  return dispatch({
    type: FETCH_ERROR,
    payload: {
      type: "error",
      titles: response,
    },
  });
}
